<div class="row mt-3">
  <div class="col-lg-12 col-xl-12">
    <div class="main-card mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title">
          <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
          Sin Elementos
        </div>
      </div>
      <div class="card-body text-center">
        <h6>
         ¡Ups! No se han encontrado registros.
        </h6>
        <ng-lottie [options]="lottieConfig" [width]="'200px'" [height]="'200px'" class="d-inline-flex">
        </ng-lottie>
      </div>
    </div>
  </div>
</div>
