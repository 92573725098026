import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {LocalStorageService} from '../../../util/local-storage.service';
import {PermissionControllerService} from '../../../util/permission-controller/permission-controller.service';
import {ReporteComprasService} from '../../../Pages/reportes/reporte-compras/services/reporte-compras.service';
import {ErrorService} from '../../../util/services/error.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  public config: PerfectScrollbarConfigInterface = {};
  cargando: boolean;
  data: any[];

  constructor(public globals: ThemeOptions, private activatedRoute: ActivatedRoute,
              public permissionController: PermissionControllerService, private localStorage: LocalStorageService,
              private reporteRcService: ReporteComprasService, private errorService: ErrorService) {
  }

  @select('config') public config$: Observable<any>;
  @Input() hasPanelHeaderTitle: boolean;
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });
    this.permissionController.crearPermisosAdm();
    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

  getComprasReport() {
    this.cargando = true;
    this.reporteRcService.getReporte1()
      .subscribe((response: any) => {
        this.data = response.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.exportAsExcelFile('planilla-compras')
      });
  }

  public exportAsExcelFile(excelFileName: string): void {
    this.cargando = true;
    const exportedData = [];
    this.data.forEach(value => {
      const obj = {
        nro_rc: value.nro_rc,
        estado_requisicion: value.estado_requisicion,
        fecha_elaboracion_requisicion: value.fecha_elaboracion_requisicion,
        descripcion: value.descripcion,
        tipo_compra_rc: value.tipo_compra_rc,
        revision: value.revision,
        tipo_rc: value.tipo_rc,
        costo_resultante: (value.costo_resultante === true || value.costo_resultante === 1)? 'Sí': 'No',
        proceso: value.proceso,
        coordinar_con: value.coordinar_con,
        remolcador: value.remolcador,
        fecha_deseada: value.fecha_solicitada_cierre,
        fecha_minima_cierre: value.fecha_minima_cierre,
        fecha_email_rc: value.fecha_email_rc,
        requisitante: value.requisitante,
        aprobada_departamento: (value.aprobada === true || value.aprobada === 1)? 'Sí': 'No',
        fecha_aprobada_departamento: value.fecha_aprobada_departamento,
        aprobacion_compras: (value.aprobacion_compras === true || value.aprobacion_compras === 1)? 'Sí': 'No',
        fecha_rc_validada: value.fecha_rc_validada,
        motivo_rechazo: value.motivo_rechazo,
        tipo_compra: value.tipo_compra,
        comprador: value.comprador,
        nro_oc: value.nro_oc,
        estado_oc: value.estado_oc,
        proveedor: value.proveedor,
        motivo_seleccion: value.motivo_seleccion,
        qty_cotizacion: value.qty_cotizacion,
        aprobacion_oc: (value.aprobacion_oc === true || value.aprobacion_oc === 1)? 'Sí': 'No',
        fecha_mail_oc: value.fecha_mail_oc,
        fecha_cierre: value.fecha_cierre,
        es_recepcion_compra: value.es_recepcion_compra
      }
      exportedData.push(obj);
    });
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
    this.cargando = false;
  }
}
