<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div [class]="!globals.toggleSidebar ? 'logo-src' : 'logo-contraido-src'" (click)="toggleSidebar()"
      style="cursor: pointer"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : !globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [config]="config">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header" *ngIf="hasPanelHeaderTitle">Módulo Principal</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="administracionMenu" *ngIf="permissionController.modulosObj['ADM']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLinkActive="active-item"
                    [routerLinkActiveOptions]="{exact: true}">
                    <i class="vsm-icon pe-7s-display1"></i>
                    <!-- <a routerLink="/administracion" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}">
                    </a> -->
                    <span class="vsm-title">Administración</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_personas/listListarPersonas'">
                        <a routerLink="/administracion/personas" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                            class="vsm-title">Personas</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_usuarios/listListarUsuarios'">
                        <a routerLink="/administracion/usuarios" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Usuarios</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_roles/listListarRolesdelSistema'">
                        <a routerLink="/administracion/roles" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Roles</span></a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_parametros/listListarParámetrosdelSistema'">
                        <a routerLink="/administracion/parametros-sistema" routerLinkActive="active-item"
                          class="vsm-link"><span class="vsm-title">Parámetros del Sistema</span></a>
                      </div>
                      <!--                      <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_modulos/listListarMódulosdelSistema'">-->
                      <!--                        <a routerLink="/administracion/modulos" routerLinkActive="active-item" class="vsm-link"><span-->
                      <!--                            class="vsm-title">Módulos</span></a>-->
                      <!--                      </div>-->
                      <div class="vsm-item"
                        *ngxPermissionsOnly="['ADM/mant_auditoria/listListarIngresosalsistema', 'ADM/mant_auditoria/listListarauditoriadeoperaciones']">
                        <a routerLink="/administracion/auditoria" routerLinkActive="active-item" class="vsm-link"><span
                            class="vsm-title">Auditoría</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>

            <div class="vsm-item" *ngIf="permissionController.modulosObj['PAR']">
              <a routerLink="/administracion/parametricos" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Paramétricos del Sistema</span>
              </a>
            </div>

            <!--            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="permissionController.modulosObj['NOTI']">-->
            <!--              <ngb-panel>-->
            <!--                <ng-template ngbPanelHeader>-->
            <!--                  <div class="vsm-header">Módulo Notificaciones</div>-->
            <!--                </ng-template>-->
            <!--              </ngb-panel>-->
            <!--              <ngb-panel id="notificacionesMenu">-->
            <!--                <ng-template ngbPanelHeader>-->
            <!--                  <button ngbPanelToggle class="vsm-link">-->
            <!--                    <i class="vsm-icon pe-7s-tools"></i>-->
            <!--                    <span class="vsm-title">Notificación</span>-->
            <!--                    <i class="vsm-arrow"></i>-->
            <!--                  </button>-->
            <!--                </ng-template>-->
            <!--                <ng-template ngbPanelContent>-->
            <!--                  <div class="vsm-dropdown">-->
            <!--                    <div class="vsm-list">-->
            <!--                      <div class="vsm-item">-->
            <!--                        <a routerLink="/notificaciones/firebase" routerLinkActive="active-item" class="vsm-link"><span-->
            <!--                          class="vsm-title">Firebase</span></a>-->
            <!--                      </div>-->
            <!--                      <div class="vsm-item">-->
            <!--                        <a routerLink="/notificaciones/onesignal" routerLinkActive="active-item" class="vsm-link"><span-->
            <!--                          class="vsm-title">One Signal</span></a>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </ng-template>-->
            <!--              </ngb-panel>-->
            <!--            </ngb-accordion>-->

            <!--             Requisiciones-->
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngIf="permissionController.modulosObj['REQUI']">
              <ngb-panel id="requisicionMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-news-paper"></i>
                    <span class="vsm-title">Requisiciones</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_requisiciones/list301'">
                        <a routerLink="/requisiciones/requisiciones" routerLinkActive="active-item" class="vsm-link"
                          [routerLinkActiveOptions]="{exact:true}">
                          <span class="vsm-title">Requisiciones</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_ordenes_compras/list304'">
                        <a routerLink="/requisiciones/ordenes-de-compra" routerLinkActive="active-item"
                          class="vsm-link">
                          <span class="vsm-title">Orden de Compra</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_instrucciones_entregas/list305'">
                        <a routerLink="/requisiciones/instruccion-de-entrega" routerLinkActive="active-item"
                          class="vsm-link">
                          <span class="vsm-title">Instrucciones de Entrega</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_autorizaciones_facturaciones/list309'">
              <a routerLink="/autorizacion-facturacion" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Autorización Facturación</span>
              </a>
            </div>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngxPermissionsOnly="'REQUI/mant_facturas/list311'">
              <ngb-panel id="requisicionMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-news-paper"></i>
                    <span class="vsm-title">Facturación</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_facturas/list311'">
                          <a routerLink="/facturas" routerLinkActive="active-item" class="vsm-link"
                            [routerLinkActiveOptions]="{exact:true}">
                            <span class="vsm-title">Facturas</span>
                          </a>
                        </div>
                      </div>
                      <div class="vsm-item">
                        <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mantener_recibo/list717'">
                          <a routerLink="/recibos" routerLinkActive="active-item" class="vsm-link"
                            [routerLinkActiveOptions]="{exact:true}">
                            <span class="vsm-title">Recibos</span>
                          </a>
                        </div>
                      </div>
                      <div class="vsm-item">
                        <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mantener_nota_credito/list727'">
                          <a routerLink="/notas-credito" routerLinkActive="active-item" class="vsm-link"
                            [routerLinkActiveOptions]="{exact:true}">
                            <span class="vsm-title">Notas de crédito</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <div class="vsm-item" *ngxPermissionsOnly="'REQUI/mant_llamados_fiscales/list312'">
              <a routerLink="/llamado-fiscal" routerLinkActive="active-item" class="vsm-link"
                [routerLinkActiveOptions]="{exact:true}">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Llamado Fiscal</span>
              </a>
            </div>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngxPermissionsOnly="'REQUI/mant_incoterms/list316'">
              <ngb-panel id="requisicionMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-global"></i>
                    <span class="vsm-title">Importaciones</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <div class="vsm-item">
                          <a routerLink="/importaciones/incoterms" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">Incoterms</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <!--            Paramétricos de Requisiciones-->
            <div class="vsm-item" *ngIf="permissionController.modulosObj['PARHID']">
              <a routerLink="/parametricos-requisiciones" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Paramétricos SiSeR</span>
              </a>
            </div>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngIf="permissionController.modulosObj['REPOR']">
              <ngb-panel id="requisicionMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-news-paper"></i>
                    <span class="vsm-title">Reportes</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <div class="vsm-item" title="Visualización de estado Global de Requisiciones"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_global/list800'">
                          <a routerLink="/reportes/global" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">Global</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Requisiciones sin Órdenes de Compra"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_ReqSinOC/list801'">
                          <a routerLink="/reportes/rc" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">RC sin OC</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Requisiciones con Órdenes de Compra"
                          *ngxPermissionsOnly="'REPOR/rep_requisicion_con_oc/list807'">
                          <a routerLink="/reportes/rc-oc" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">RC con OC</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Órdenes de Compra sin Instrucciones de Entrega"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_OCSinIE/list802'">
                          <a routerLink="/reportes/oc" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">OC sin IE</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Instrucciones de Entrega sin Autorizaciones de Facturación"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_IESinAF/list803'">
                          <a routerLink="/reportes/ie" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">IE sin AF</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Autorización de Facturación con Facturas Sin Recibir"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_AFSinF/list804'">
                          <a routerLink="/reportes/af" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">AF sin FA</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Facturas sin Llamado Fiscal"
                          *ngxPermissionsOnly="'REPOR/mant_reporte_FSinLF/list805'">
                          <a routerLink="/reportes/fa" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">FA sin LF</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Cantidad de RCs Recibidas"
                          *ngxPermissionsOnly="'REPOR/rep_requisicion_compra_cantidad/list809'">
                          <a routerLink="/reportes/rc-compradores" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">Cantidad de RCs Recibidas</span>
                          </a>
                        </div>
                        <div class="vsm-item" title="Detalle de RCs Recibidas"
                          *ngxPermissionsOnly="'REPOR/rep_requisicion_comprador/list811'">
                          <a routerLink="/reportes/rc-comprador" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">Detalle de RCs Recibidas</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>