import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusquedaOrdenesCompraComponent } from './busqueda-ordenes-compra.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {BotonFiltroModule} from '../boton-filtro/boton-filtro.module';
import {SpinnerModule} from '../spinner/spinner.module';
import {DatosNoEncontradosModule} from '../datos-no-encontrados/datos-no-encontrados.module';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTableModule} from '@angular/material/table';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [BusquedaOrdenesCompraComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BotonFiltroModule,
    SpinnerModule,
    DatosNoEncontradosModule,
    NgbAlertModule,
    MatTableModule,
    FlexModule,
    ExtendedModule
  ],
  bootstrap: [BusquedaOrdenesCompraComponent]
})
export class BusquedaOrdenesCompraModule { }
