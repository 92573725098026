import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatosNoEncontradosComponent } from './datos-no-encontrados.component';

@NgModule({
  declarations: [DatosNoEncontradosComponent],
  imports: [
    CommonModule,
    LottieModule
  ],
  exports: [DatosNoEncontradosComponent]
})
export class DatosNoEncontradosModule { }
