<div class="text-center d-block card-footer">

  <div class="button-row">
    <button type="button" class="col-md-2 mt-2 mr-2 btn-shadow-primary btn btn-primary btn-lg" (click)="editar()"
            *ngIf="editable" [disabled]="!showEditarButton">Editar
    </button>
    <button type="button" class="col-md-2 mt-2 mr-2 btn-shadow-primary btn btn-light btn-lg" (click)="volver()"
            *ngIf="editable && !isModal">Volver
    </button>
    <button type="button" class="col-md-2 mt-2 mr-2 btn-shadow-primary btn btn-light btn-lg" (click)="cerrar()"
            *ngIf="editable && isModal">Cerrar
    </button>
    <button type="button" class="col-md-2 mt-2 mr-2 btn-shadow-primary btn btn-success btn-lg" (click)="actualizar()" [disabled]="process || !valid"
            *ngIf="!editable"><span class="spinner-border spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"
            *ngIf="process"></span>{{buttonTextActualizar}}
    </button>
    <button type="button" class="col-md-2 mt-2 mr-2 btn-shadow-primary btn btn-light btn-lg" (click)="cancelar()"
            *ngIf="!editable" [disabled]="process">Cancelar
    </button>
  </div>

</div>
