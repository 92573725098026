import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html'
})
export class FooterMenuComponent {

  date = new Date().getFullYear();

  onLogoClick(): void {
    const urlEsolutions = 'http://www.enterprisesolutions.com.py/';
    window.open(urlEsolutions, '_blank');
  }

}
