import {Globals} from './../../../Globals';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient, private global: Globals) {
  }

  /*
      FilesService @ subirArchivo
        Para el uso de este servicio, la estructura del Form Data debe ser la siguiente:
        formData{
          file: `algún archivo aquí`
          method: `PUT, POST`
          id: `el id de la entidad a la cual se asociará el archivo`
          entity: `App\Http\Domain\Entities\{Nombre de la Entidad}`
          record: `Nombre del campo en la entidad dada`
          folder: `Nombre del directorio donde se guardarán los archivos`
        }
  */
  subirArchivo(formData: FormData) {
    const url = this.global.API_ENDPOINT_ES + '/files';
    return this.http.post(url, formData);
  }

  /*
    FilesService @ getUserFiles
      Permite obtener los archivos referente al usuario, por ejemplo, la foto de perfil
   */

  getUserFiles(nombreArchivo: string): Observable<Blob> {
    const url = this.global.API_ENDPOINT_ES + '/usersFiles/' + nombreArchivo;
    return this.http.get(url, {responseType: 'blob'});
  }

  getArchivo(path) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/download/${path}`;
    return this.http.get(url, {headers});
  }
}
