import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  constructor(private http: HttpClient,
              private global: Globals) {
  }

  getRoles() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboRoles';
    return this.http.get(url, {headers});
  }

  getPersonas(search = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboPersonas', search, null);
    return this.http.get(url, {headers});
  }

  getEstadosCivilesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboEstadosCiviles';
    return this.http.get(url, {headers});
  }

  getPaisesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPaises';
    return this.http.get(url, {headers});
  }

  getActividadesLaboralesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboActividadesLaborales';
    return this.http.get(url, {headers});
  }

  getTipoDocumentosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDocumentosTipos';
    return this.http.get(url, {headers});
  }

  getTipoTelefonosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTelefonosTipos';
    return this.http.get(url, {headers});
  }

  getTipoDireccionesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDireccionesTipos';
    return this.http.get(url, {headers});
  }

  getTiposContactoCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboContactosTipos';
    return this.http.get(url, {headers});
  }

  getCiudadesCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboCiudades';
    return this.http.get(url, {headers});
  }

  getUsuariosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboUsers';
    return this.http.get(url, {headers});
  }
  getModulosCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboModulos';
    return this.http.get(url, {headers});
  }
  getFuncionalidadesCombo(idModulo) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboModulos/' + idModulo + '/funcionalidades';
    return this.http.get(url, {headers});
  }
  getPermisosCombo(idFuncionalidad) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboFuncionalidades/' + idFuncionalidad + '/permisos';
    return this.http.get(url, {headers});
  }
  getDepartamentosCombo(idPais) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPaises/' + idPais + '/departamentos';
    return this.http.get(url, {headers});
  }
  getCiudadesCombo2(idDepartamento) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboDepartamentos/' + idDepartamento + '/ciudades';
    return this.http.get(url, {headers});
  }
}
