import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutService} from '../../../../services/layout.service';
import {ErrorService} from '../../../../../util/services/error.service';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
})
export class DotsComponent implements OnInit {
  cargando: boolean;
  total: number;
  pagination = {page: 1, per_page: 10};
  selected = null;
  displayedColumns = ['codigos', 'usuario_req', 'tipo_rc'];

  requisiciones = [];
  searchForm: FormGroup;


  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
              private layoutService: LayoutService, private errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.makeSearchForm();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      num_req: '',
      num_seg: '',
      estado: '',
      orden_compra: '',
      inst_servicio: ''
    });
  }

  getRequisiciones() {
    this.cargando = true;
    this.layoutService.getRcPorVencer()
      .subscribe((response: any) => {
        if (response.success) {
          this.requisiciones = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  verDetalle(id: number) {
    this.router.navigate(['/requisiciones/requisiciones/', id], {relativeTo: this.route});
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    return;
  }

  pageChange() {
    return;
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

}
