import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Combo} from '../../interfaces/combo';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorService} from '../../services/error.service';
import {CombosService} from '../../services/combos/combos.service';

@Component({
  selector: 'app-grilla-personas',
  templateUrl: './grilla-personas.component.html',
  styleUrls: ['./grilla-personas.component.sass']
})
export class GrillaPersonasComponent implements OnInit {

  cargando: boolean;
  personaSearchForm: FormGroup;
  personas: any[] = [];
  dato = [];
  mostrarInformeSocial: boolean;
  filtrosCentros: boolean = false;
  sinBusqueda: boolean = true;
  centros: Combo[];
  displayedColumns = ['nombre', 'apellido', 'acciones'];
  private pagination = { page: 1, per_page: 10 };

  constructor(
    private matDialogRef: MatDialogRef<GrillaPersonasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fB: FormBuilder,
    private errorService: ErrorService,
    private services: CombosService
  ) { }

  ngOnInit() {
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.personaSearchForm = this.fB.group({
      nombre: null,
      apellido: null,
      documento: null,
      withoutUser: null,
    });
  }


  get() {
    this.cargando = true;
    this.services.getPersonas(this.personaSearchForm.value)
      .subscribe((response: any) => {
        if (response.success) {
          this.personas = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  personaSeleccionada(object) {
    this.matDialogRef.close(object)
  }

  volver(){
    this.matDialogRef.close(false);
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    const nombre = this.personaSearchForm.get('nombre').value;
    const apellido = this.personaSearchForm.get('apellido').value;
    const documento = this.personaSearchForm.get('documento').value;
    console.log({ nombre, apellido, documento });
    if (!nombre && !apellido && !documento){
      // check valores vacios
      console.log('Valores vacíos');
      this.sinBusqueda = true;
      return;
    } else {
      this.sinBusqueda = false;
    }
    this.get();
  }

  onReset() {
    this.personaSearchForm.reset();
    this.sinBusqueda = true;
  }
}
