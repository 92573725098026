import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../util/local-storage.service';
import {UserData} from '../Login/interfaces/login';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  public user: UserData;
  constructor(private localStorage: LocalStorageService) { }

  ngOnInit() {
   this.user = this.localStorage.getUserFromStorage().userData;
  }

}
