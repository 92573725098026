<form [formGroup]="changePasswordForm">
  <div fxLayout="row">
    <div fxFlex>
      <h5 class="mb-0">Cambiar Contraseña</h5>
      <div class="divider mt-0"></div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="1%">
    <mat-form-field fxFlex>
      <input matInput type="email" placeholder="Ingrese su Email" aria-label="Search" name="search"
             formControlName="email">
    </mat-form-field>
    <mat-form-field fxFlex>
      <input matInput type="password" placeholder="Contraseña Actual" aria-label="Search" name="search"
             formControlName="password_current">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="1%">
    <mat-form-field fxFlex>
      <input matInput type="password" placeholder="Nueva contraseña" aria-label="Search" name="search"
        autocomplete="new-password" formControlName="password">
        <mat-error
          *ngIf="changePasswordForm.get('password').hasError('minlength') && changePasswordForm.get('password').touched">
          Debe introducir al menos {{parametros.MINPASSWORD}} caracteres.
        </mat-error>
        <mat-error
          *ngIf="changePasswordForm.get('password').hasError('maxlength') && changePasswordForm.get('password').touched">
          Debe introducir sólo hasta {{parametros.MAXPASSWORD}} caracteres.
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <input matInput type="password" placeholder="Repita la contraseña" aria-label="Search" name="search"
        autocomplete="new-password" formControlName="password_confirmation">
    </mat-form-field>
  </div>
</form>
<div fxLayout="row">
  <app-botonera-edicion fxFlex [editable]="disabled" [isModal]="true" (clickUpdateEvent)="validar()" [buttonTextActualizar]="'Guardar'" [controlarPermiso]="false"
    (clickCancelEvent)="editableSwitcher('C')" (clickEditEvent)="editableSwitcher('E')" (clickCloseEvent)="volver()" [valid]="changePasswordForm.valid">
  </app-botonera-edicion>
</div>
