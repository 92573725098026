<mat-card>
  <button class="close" mat-button (click)="volver()">
          <span class="material-icons text-danger">
          clear
          </span>
  </button>

  <mat-card-header>
    <mat-card-title>Búsqueda de órdenes de compra</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="main-card mb-3 card">
      <div class="card-body pb-0">
        <form [formGroup]="searchForm">
          <div class="form-row">
            <mat-form-field class="col-lg-3 col-md-3 col-sm-6">
              <input matInput type="search" placeholder="Número de OC" aria-label="Search" formControlName="valor">
            </mat-form-field>
            <mat-form-field class="col-lg-3 col-md-3 col-sm-6">
              <input matInput type="search" placeholder="Código de OC" aria-label="Search" formControlName="codigo">
            </mat-form-field>
          </div>
          <app-boton-filtro [ocultarXLS]="true" (clickEvent)="onClick($event)">
          </app-boton-filtro>
        </form>
      </div>
      <hr>

      <div class="row mt-5 mb-3" *ngIf="sinBusqueda">
        <div class="col-md-12 col-sm-12">
          <p>
            <ngb-alert [dismissible]="false" type="info">
              <i class="fa fa-info-circle mr-1" style="font-size: 23px;"></i>
              <span class="font-weight-bold"> Por favor, utilice los filtros de búsqueda para encontrar a una orden de compra en específico. </span>
            </ngb-alert>
          </p>
        </div>
      </div>

      <ng-template [ngIf]="sinBusqueda === false">
        <div class="card-body" *ngIf="!cargando && data.length != 0">
          <div fxLayout="row">
            <mat-table style="width: 100%;" [dataSource]="data" class="mat-table-min-height tabla-senadis">
              <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef fxFlex="30%" fxFlex.lt-lg="30%" style="justify-content: center;"> Órden de Compra
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="30%" fxFlex.lt-lg="30%" style="justify-content: center;">
                  <div class="widget-content mt-2 mb-2 p-0">
                    <div class="widget-content-wrapper">
                      <div class="container-fluid pl-0 text-center">
                        <div class="widget-content-right">
                          <div class="widget-heading">{{element.nombre}} - Código: {{element.codigo}}</div>
                          <div class="widget-heading" *ngIf="element.proveedor">Proveedor: {{element.proveedor.nombre}}</div>
                          <div class="widget-heading" *ngIf="element.tipo_oc">Tipo de OC:
                          <span [ngSwitch]="element.tipo_oc">
                            <span *ngSwitchCase="'C'">Contrato</span>
                            <span *ngSwitchCase="'X'">Consumo</span>
                            <span *ngSwitchCase="'S'">Spot</span>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="apellido">
                <mat-header-cell *matHeaderCellDef fxFlex="30%" fxHide.md fxHide.sm fxHide.xs style="justify-content: center;"> Requisicion
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="30%" fxHide.md fxHide.sm fxHide.xs style="justify-content: center;">
                  <ng-template [ngIf]="element.requisicion" [ngIfElse]="sinDocumento">
                    <p>{{element.requisicion.nro_rc}}</p>
                  </ng-template>
                  <ng-template #sinDocumento>
                    <p>Sin Datos</p>
                  </ng-template>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="acciones">
                <mat-header-cell *matHeaderCellDef fxFlex class="justify-content-center">
                  Acciones
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex class="justify-content-center">
                  <div class="btn-group">
                    <button type="button" mat-flat-button color="primary" (click)="onSelected(element)">Seleccionar</button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns; let indice = index" style="cursor: pointer;">
              </mat-row>
            </mat-table>
          </div>
          <br>
          <!-- <div class="row justify-content-center mr-2 ml-2" *ngIf="total > 0">
            <div class="col-md-6 text-success">
              Total de Registros: <strong>{{total}}</strong>
            </div>
            <div class="col-md-6">
              <div class="pull-right">
                <ngb-pagination [(page)]="pagination.page" [pageSize]="pagination.per_page" [maxSize]="5"
                  [collectionSize]="total" (pageChange)="pageChange()" size="sm"></ngb-pagination>
              </div>
            </div>
          </div> -->
        </div>
        <div class="card-body" *ngIf="cargando">
          <app-spinner></app-spinner>
        </div>
        <app-datos-no-encontrados *ngIf="!cargando && data && data.length == 0">
        </app-datos-no-encontrados>
      </ng-template>

    </div>
  </mat-card-content>

</mat-card>
