<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <div class="logo-src"></div>
  <div class="app-header__content">
    <div class="app-header-left">
      <!--<app-search-box></app-search-box>-->
      <!--<app-mega-menu></app-mega-menu>-->
    </div>
    <div class="app-header-right">
      <app-dots *ngxPermissionsOnly="'REQUI/obtener_requisicion_a_vencer/list715'"></app-dots>
      <a mat-icon-button title="Ver documentación" href="./assets/manual/ManualSiserV2.pdf" download="ManualSiserV2.pdf" target="_blank">
        <mat-icon>menu_book</mat-icon>
      </a>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
        <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
                (click)="toggleHeaderMobile()">
            <div class="btn-icon-wrapper">
                <i class="fa fa-ellipsis-v"></i>
            </div>
        </button>
    </span>
  </div>
</div>
