import {Component, OnInit} from '@angular/core';
import {HidroviasCombosService} from '../services/combos/hbsa/hidrovias-combos.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ErrorService} from '../services/error.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-busqueda-ordenes-compra',
  templateUrl: './busqueda-ordenes-compra.component.html',
  styleUrls: ['./busqueda-ordenes-compra.component.sass']
})
export class BusquedaOrdenesCompraComponent implements OnInit {

  searchForm: FormGroup;
  data: any[];
  cargando: boolean;
  sinBusqueda: boolean = true;
  displayedColumns = ['nombre', 'apellido', 'acciones'];

  constructor(private service: HidroviasCombosService, private fB: FormBuilder,
              private errorService: ErrorService,
              private matDialogRef: MatDialogRef<BusquedaOrdenesCompraComponent>) {
  }

  ngOnInit(): void {
    this.makeForm();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      valor: [null],
      codigo: [null],
    });
  }

  onSelected(ordenDeCompra) {
    this.matDialogRef.close(ordenDeCompra);
  }

  getOrdenesCompra() {
    this.cargando = true;
    this.service.getOrdenesDeCompra(this.searchForm.value)
      .subscribe((response: any) => {
        if (response.success) {
          this.data = response.data;
          this.sinBusqueda = false;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  volver() {
    this.matDialogRef.close(null);
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.searchForm.reset();
    this.sinBusqueda = true;
  }

  filtrar() {
    this.getOrdenesCompra();
  }
}
