import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class HidroviasCombosService {

  constructor(private http: HttpClient,
    private global: Globals) {
  }

  getTiposProcesos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTiposProcesos';
    return this.http.get(url, { headers });
  }

  getTiposRevisiones() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboRevisiones';
    return this.http.get(url, { headers });
  }

  getRemolcadores() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboRemolcadores';
    return this.http.get(url, { headers });
  }

  getTiposRequisiciones() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboTiposRequisiones';
    return this.http.get(url, { headers });
  }

  getSuperintendentes() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboSuperintendentes';
    return this.http.get(url, { headers });
  }

  getProveedores(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/comboProveedores`, value, null);
    return this.http.get(url, { headers });
  }

  getMotivosSeleccion() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboMotivosSeleccion';
    return this.http.get(url, { headers });
  }

  getEmpresas() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboEmpresas';
    return this.http.get(url, { headers });
  }

  getUnidadesDeMedidas() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboUnidadesMedidas';
    return this.http.get(url, { headers });
  }

  getRequisiciones(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/comboRequisiciones`, value, null);
    return this.http.get(url, { headers });
  }

  getOrdenesDeCompra(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboOrdenesCompras', value, null);
    return this.http.get(url, { headers });
  }

  getOrdenesDeCompraByRequisicion(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboOrdenesComprasByRequisiciones/${id}`;
    return this.http.get(url, { headers });
  }

  getOrdenesDeCompraContrato(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboOrdenesComprasMadres', value, null);
    return this.http.get(url, { headers });
  }

  getInstruccionesDeEntrega(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboInstruccionesEntregas', value, null);
    return this.http.get(url, { headers });
  }

  getInstruccionesDeEntregaByRequisicion(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboInstruccionesEntregasByRequisiciones/${id}`;
    return this.http.get(url, { headers });
  }

  getFacturasByRequisicion(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboFacturasByRequisiciones/${id}`;
    return this.http.get(url, { headers });
  }

  getAutorizacionesFacturasByRequisicion(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboAutorizacionesFacturacionesByRequisiciones/${id}`;
    return this.http.get(url, { headers });
  }

  getTiposCompras(activo: boolean = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/comboTiposCompras`, { activo }, null);
    return this.http.get(url, { headers });
  }

  getMonedas() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboMonedas';
    return this.http.get(url, { headers });
  }

  getDepartamentos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboParamDepartamentos';
    return this.http.get(url, { headers });
  }

  getCuentasContables() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboCuentasContables';
    return this.http.get(url, { headers });
  }

  getCentrosCostos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboCentrosCostos';
    return this.http.get(url, { headers });
  }

  getComprasUsuarios(dpto) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboUsuariosDepartamentos?tipo=${dpto}`;
    return this.http.get(url, { headers });
  }

  getComboImportaciones() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboImportaciones`;
    return this.http.get(url, { headers });
  }

  getEstadosFacturaCombo() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboEstadosFacturas`;
    return this.http.get(url, { headers });
  }

  getOrdenesCompraCombos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/comboEstadosOrdenesCompras`;
    return this.http.get(url, { headers });
  }

  getEmailsDisponibles(search = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/comboEmails`, search, null);
    return this.http.get(url, { headers });
  }

  getFacturas(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboFacturas', value, null);
    return this.http.get(url, { headers });
  }

  getReqCompradores(value = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/comboReqCompradores', value, null);
    return this.http.get(url, { headers });
  }


}
