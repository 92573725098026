import { NgxPermissionsModule } from 'ngx-permissions';
import { MatTabsModule } from '@angular/material/tabs';
import { BotoneraEdicionModule } from './../util/botonera-edicion/botonera-edicion.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppsLayoutComponent } from './apps-layout/apps-layout.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { FooterComponent } from './Components/footer/footer.component';
import { HeaderComponent } from './Components/header/header.component';
import { PageTitleComponent } from './Components/page-title/page-title.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { PagesLayoutComponent } from './pages-layout/pages-layout.component';
import { RouterModule } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { OptionsDrawerComponent } from '../ThemeOptions/options-drawer/options-drawer.component';
import { DrawerComponent } from './Components/header/elements/drawer/drawer.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DotsComponent } from './Components/header/elements/dots/dots.component';
import { FooterDotsComponent } from './Components/footer/elements/footer-dots/footer-dots.component';
import { FooterMenuComponent } from './Components/footer/elements/footer-menu/footer-menu.component';
import { UserBoxComponent } from './Components/header/elements/user-box/user-box.component';
import { MegamenuComponent } from './Components/header/elements/mega-menu/mega-menu.component';
import { MegapopoverComponent } from './Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import { SearchBoxComponent } from './Components/header/elements/search-box/search-box.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogoComponent } from './Components/sidebar/elements/logo/logo.component';
import { AppComponent } from '../app.component';
import { UserProfileDialogComponent } from './Components/header/elements/user-box/user-profile-dialog/user-profile-dialog.component';
import { UserSettingsComponent } from './Components/header/elements/user-box/user-profile-dialog/user-settings/user-settings.component';
import { UserInfoComponent } from './Components/header/elements/user-box/user-profile-dialog/user-info/user-info.component';
import {SpinnerModule} from '../util/spinner/spinner.module';
import {DatosNoEncontradosModule} from '../util/datos-no-encontrados/datos-no-encontrados.module';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    AppComponent,
    AppsLayoutComponent,
    BaseLayoutComponent,
    OptionsDrawerComponent,
    DrawerComponent,
    FooterComponent,
    HeaderComponent,
    PageTitleComponent,
    SidebarComponent,
    LogoComponent,
    PagesLayoutComponent,
    DotsComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    UserBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    SearchBoxComponent,
    UserProfileDialogComponent,
    UserSettingsComponent,
    UserInfoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoadingBarModule,
    PerfectScrollbarModule,
    RoundProgressModule,
    MatCheckboxModule,
    NgbModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    BotoneraEdicionModule,
    MatTabsModule,
    NgxPermissionsModule.forChild(),
    SpinnerModule,
    DatosNoEncontradosModule,
    MatTableModule
  ],
  exports: [
    AppComponent,
    AppsLayoutComponent,
    BaseLayoutComponent,
    OptionsDrawerComponent,
    DrawerComponent,
    FooterComponent,
    HeaderComponent,
    PageTitleComponent,
    SidebarComponent,
    LogoComponent,
    PagesLayoutComponent,
    DotsComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    UserBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    SearchBoxComponent
  ], entryComponents: [
    UserProfileDialogComponent
  ],
})
export class LayoutModule {
}
