import { BotonFiltroComponent } from './boton-filtro.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [BotonFiltroComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        NgbModule
    ],
  exports: [BotonFiltroComponent]
})
export class BotonFiltroModule { }
