<div class="form-row">
  <ng-template [ngIf]="!ocultarFiltro">
    <div class="col-lg-2 col-md-2">
      <button class="btn-block" mat-raised-button (click)="filtrar()" type="submit">
        <mat-icon><i class="pe-7s-filter btn-icon-wrapper text-secondary"></i></mat-icon> Filtrar
      </button>
    </div>
    <div class="col-lg-2 col-md-2 mt-lg-0 mt-md-0 mt-2">
      <button class="btn-block" mat-raised-button type="button" (click)="reset()">
        <mat-icon><i class="pe-7s-trash btn-icon-wrapper text-secondary"></i></mat-icon> Limpiar
      </button>
    </div>
  </ng-template>
  <ng-template [ngIf]="ocultarFiltro">
    <div class="col-lg-4 col-md-4"></div>
  </ng-template>
  <div [ngClass]="{
      'col-lg-6 col-md-6': (!mostrarDescargar && mostrarSubir) || (mostrarDescargar && !mostrarSubir),
      'col-lg-4 col-md-4': mostrarSubir && mostrarDescargar
    }"></div>
  <div class="col-lg-2 col-md-2 mt-lg-0 mt-md-0 mt-2" *ngIf="mostrarDescargar">
    <div class="button-group text-center">
      <div class="d-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn-block btn-lg" mat-raised-button [disabled]="downloading">
          <span class="spinner-border spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true" *ngIf="downloading"></span>
          <mat-icon><i class="pe-7s-download btn-icon-wrapper text-primary"></i></mat-icon> {{textoDescargar}}
        </button>
        <div ngbDropdownMenu>
          <ul class="nav flex-column">
            <li class="nav-item" *ngIf="!ocultarPDF"><a class="nav-link" (click)="pdf()"><i
              class="nav-link-icon fa fa-file-pdf-o"></i><span>PDF</span>
            </a></li>
            <li class="nav-item" *ngIf="!ocultarCSV"><a class="nav-link" (click)="csv()"><i
              class="nav-link-icon fa fa-file-text-o"></i><span>CSV</span></a></li>
            <li class="nav-item" *ngIf="!ocultarXLS"><a class="nav-link" (click)="excel()"><i
              class="nav-link-icon fa fa-file-excel-o"></i><span>Excel</span></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2 mt-lg-0 mt-md-0 mt-2" *ngIf="mostrarSubir">
    <button class="btn-block" mat-raised-button type="button" data-toggle="tooltip" [disabled]="uploading"
            data-placement="top" title="Subir Archivo" (click)="uploadFile()">
      <span class="spinner-border spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true" *ngIf="uploading"></span>
      <mat-icon><i class="pe-7s-upload btn-icon-wrapper text-success"></i></mat-icon> {{textoSubir}}
    </button>
  </div>
</div>
