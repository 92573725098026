<form [formGroup]="changeInfoForm">
  <div fxLayout="column" fxLayoutAlign="space-around">
  <div fxLayout="row">
    <div fxFlex>
      <h5 class="mb-0">Datos Personales</h5>
      <div class="divider mt-0"></div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between">
    <div fxFlex="25%">
      <div *ngIf="disabledEmail">
        <b>Correo electrónico: </b>{{userInfo.email}}
        <button type="button" class="btn btn-default btn-sm" title="Editar" (click)="editableSwitcher('email')">
          <i class="lnr-pencil mr-2 text-primary opacity-6"> </i>
        </button>
      </div>
      <div *ngIf="!disabledEmail">
        <b>Correo electrónico: </b>
        <div class="input-group mb-3">
          <input type="email" class="form-control form-control-sm" placeholder="Correo"
                 aria-label="Correo" aria-describedby="basic-addon1" formControlName="email">
          <div class="input-group-append">
            <button type="button" class="btn btn-default btn-sm" title="Guardar" (click)="updateProfile('email')">
              <i class="pe-7s-diskette mr-2 text-success"> </i>
            </button>
            <button type="button" class="btn btn-default btn-sm" title="Cancelar" (click)="editableSwitcher('email', true)">
              <i class="lnr-cross mr-2 text-danger opacity-6"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="25%">
      <b>Documento: </b>
      <ng-template [ngIf]="userInfo.documento_completo" [ngIfElse]="noDocumento">
        {{userInfo.documento_completo}}
      </ng-template>
      <ng-template #noDocumento>
        No proveída
      </ng-template>
    </div>
<!--    <div fxFlex="25%">-->
<!--      <b>Número de teléfono: </b>-->
<!--      <ng-template [ngIf]="userInfo.telefono" [ngIfElse]="noTelefono">-->
<!--        {{userInfo.telefono}}-->
<!--      </ng-template>-->
<!--      <ng-template #noTelefono>-->
<!--        No proveído-->
<!--      </ng-template>-->
<!--    </div>-->
  </div>
  <div fxLayout="row" class="mt-4">
    <div fxFlex>
      <h5 class="mb-0">Datos de la Cuenta</h5>
      <div class="divider mt-0"></div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between">
    <div fxFlex="25%">
        <div *ngIf="disabledName">
          <b>Usuario: </b>
          <ng-template [ngIf]="userInfo.name">
            {{userInfo.name}}
          </ng-template>
          <button type="button" class="btn btn-default btn-sm" title="Editar" (click)="editableSwitcher('name')">
            <i class="lnr-pencil mr-2 text-primary opacity-6"> </i>
          </button>
        </div>
        <div *ngIf="!disabledName">
          <b>Usuario: </b>
          <div class="input-group mb-3">
            <input type="text" class="form-control form-control-sm" placeholder="Usuario"
                   aria-label="Usuario" aria-describedby="basic-addon1" formControlName="name">
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-sm" title="Guardar" (click)="updateProfile('name')">
                <i class="pe-7s-diskette mr-2 text-success"> </i>
              </button>
              <button type="button" class="btn btn-default btn-sm" title="Cancelar" (click)="editableSwitcher('name', true)">
                <i class="lnr-cross mr-2 text-danger opacity-6"></i>
              </button>
            </div>
          </div>
        </div>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <div fxflex="50%">
        <b>Acceso web: </b>
        <ng-template [ngIf]="userInfo.acceso_web" [ngIfElse]="sinAcesso">
          Sí
        </ng-template>
        <ng-template #sinAcesso>
          No
        </ng-template>

      </div>
      <div fxflex="50%">
        <b>Acceso app: </b>
        <ng-template [ngIf]="userInfo.acceso_app" [ngIfElse]="sinAcessoApp">
          Sí
        </ng-template>
        <ng-template #sinAcessoApp>
          No
        </ng-template>
      </div>
    </div>
  </div>
</div>
</form>
