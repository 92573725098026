import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class ReporteComprasService {

  constructor(private http: HttpClient, private globals: Globals) {
  }

    getReporte1(search = null) {
    const headers = this.globals.getHeader();
    const url = this.globals.makeUrl(`/reportesCompras?page=disable`, search, null);
    return this.http.get(url, {headers});
  }
}
