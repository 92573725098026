import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../Globals';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient, private globals: Globals) {
  }

  getRcPorVencer() {
    const headers = this.globals.getHeader();
    const url = this.globals.API_ENDPOINT_ES + `/requisicionesCompras/comprador`;
    return this.http.get(url, {headers});
  }
}
