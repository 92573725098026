<div class="h-100 bg-night-sky bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <!-- <div class="app-logo-horizontal mx-auto mb-3"></div> -->
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <ng-template [ngIf]="iniciando">
            <form [formGroup]="loginForm">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                  <h4 class="mt-2">
                    <div>Bienvenido,</div>
                    <span>Por favor inicie sesión en su cuenta.</span>
                  </h4>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around">
                  <mat-form-field fxFlex>
                    <input id="exampleInput1" type="text" aria-required="true" matInput
                      formControlName="username" autocomplete="email">
                    <mat-placeholder *ngIf="loginPor === 'email'">Ingrese su email</mat-placeholder>
                    <mat-placeholder *ngIf="loginPor === 'doc'">Ingrese su número de documento</mat-placeholder>
                    <mat-hint *ngIf="loginPor === 'email'"> Nunca compartiremos su correo electrónico con nadie más.</mat-hint>
                    <mat-hint *ngIf="loginPor === 'doc'"> Nunca compartiremos su información con nadie más.</mat-hint>
                    <mat-error
                      *ngIf="loginForm.controls.username.hasError('email') && !loginForm.controls.username.hasError('required')">
                      Por favor, ingrese una dirección de email valida.
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls.username.hasError('required') && loginPor === 'email'">
                      El email es <strong>requerido</strong>
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls.username.hasError('required') && loginPor === 'doc'">
                      El número de documento es <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex class="mt-2">
                    <input id="exampleInput2" type="password" placeholder="Ingrese su contraseña" aria-required="true"
                      matInput formControlName="password" autocomplete="current-password">
                  </mat-form-field>
                </div>
                <!--              <div class="custom-control custom-checkbox"><input id="exampleCheck" type="checkbox" name="check"-->
                <!--                                                                 autocomplete="off" class="custom-control-input"-->
                <!--                                                                 value="true"><label for="exampleCheck"-->
                <!--                                                                                     class="custom-control-label">-->
                <!--                Mantener la sesión iniciada-->
                <!--              </label></div>-->
                <div class="divider"></div>
                <h6 class="mb-0">
                  ¿Ha olvidado su contraseña?
                  <a class="text-primary" (click)="cambiarInicioRegistro('recuperar')" style="cursor: pointer;"> Haga
                    click aquí </a>
                </h6>
              </div>
              <div class="modal-footer clearfix">
                <div class="float-left">
                  <button type="submit" class="btn btn-light btn-lg" (click)="iniciarSesion()"
                    [disabled]="!loginForm.valid || cargando">
                    <span class="spinner-border spinner-grow spinner-grow-sm text-primary" role="status"
                      aria-hidden="true" *ngIf="cargando"></span>
                    Iniciar Sesión
                  </button>
                </div>
                <div class="float-right" *ngIf="tieneRegistroActivado">
                  <button class="btn-lg btn btn-primary"
                    (click)="cambiarInicioRegistro('registro')">Registrarse</button>
                </div>
              </div>
            </form>
          </ng-template>
          <ng-template [ngIf]="registro">
            <app-registrar-usuario (hide)="cambiarInicioRegistro('inicio')"
              [minpassword]="parametrosIniciales.MINPASSWORD.MINPASSWORD"
              [maxpassword]="parametrosIniciales.MAXPASSWORD.MAXPASSWORD"></app-registrar-usuario>
          </ng-template>
          <ng-template [ngIf]="recuperar">
            <app-olvide-contrasenha (hide)="cambiarInicioRegistro('inicio')" [loginType]="loginPor">
            </app-olvide-contrasenha>
          </ng-template>
          <ng-template [ngIf]="cargandoServicioInicial">
            <div class="text-center">
              <app-spinner></app-spinner>
            </div>
          </ng-template>
        </div>
      </div>


      <div class="text-center text-white opacity-8 mt-3">
        Powered by Enterprise Solutions © 2020
      </div>
    </div>
  </div>
</div>